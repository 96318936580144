import React from 'react'
import { compose, pure } from 'recompose'
import { withNamespaces } from 'react-i18next'
import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'

import SecondaryLayout from '../../components/layouts/SecondaryLayout'
import PresseRadio from '../../components/references/PresseRadio'

const PresseRadioPage = ({ t }) => (
  <SecondaryLayout subtitle={t('titre')} page="ComponentReferencesPresseRadio">
    <PresseRadio />
  </SecondaryLayout>
)

const echance = compose(
  pure,
  SetLanguageFromPage(),
  withNamespaces('presse-radio')
)

export default echance(PresseRadioPage)
